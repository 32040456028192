import React, { useState, useEffect } from 'react'
// import PropTypes from 'prop-types'
import { Row, Col, Button, Modal } from 'react-bootstrap'
import styles from './Comment.module.scss'
import logo from '../../assets/logo48x48.svg'
import no_photo from '../../assets/lineups/no_photo.png'
import classNames from 'classnames'
import deleteIcon from '../../assets/eventEditorIcons/deleteIcon.svg'
import { useIntl } from 'react-intl'

import overflowIcon from '../../assets/matchCommentsIcons/overflow.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faMarker, faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons'
import { useSelector } from 'react-redux'
const EVENT_OFFLINE = '#969696'
const EVENT_USER = '#37c90a'
const TEXT_EVENT_OFFLINE = '#999999'
const BACKGROUND_EVENT_OFFLINE = '#e7e7e7'
const MODALITIES_MARCADOR_INDEX = 8
const zerozeroVideosId = "28"
const photosId = "97"
const uniformId = "109"

const Comment = ({
  comment,
  events,
  id,
  setupEdit,
  matchInfo,
  canEdit,
  resolveConflict,
  handleDelete,
  handleResend,
  arrowUpActive = false,
  arrowDownActive = false,
  onChangeOrder = () => { },
}) => {
  const userId = localStorage.getItem('user-id')
  const [commentHtml, setCommentHtml] = useState(comment.text)
  const [needsCatalog, setNeedsCatalog] = useState(false)
  const [needsPenCatalog, setNeedsPenCatalog] = useState(false)
  const [icon, setIcon] = useState('')
  const [isCataloged, setIsCataloged] = useState(false);
  const [showImageFullScreen, setShowImageFullScreen] = useState(false)
  const isZZMember = useSelector((state) => state.auth.zz_member)
  const { formatMessage: f } = useIntl()

  const setupEditComment = (e) => {
    setupEdit(id)
  }

  const setupDeleteComment = (e) => {
    handleDelete(id)
  }

  useEffect(() => {
    const getIcon = (fk) => {
      const filteredArray = events.filter((event) => event.id === fk)
      const event = filteredArray[0]
      setNeedsCatalog(event.catalogacao === '1')
      setNeedsPenCatalog(event.catalogacao_pen === '1')
      if (event.img_path === '') {
        setIcon(logo)
      } else {
        setIcon(`https://www.zerozero.pt/images/mc/live/${event.img_path}`)
      }
    }

    try {
      getIcon(comment.fk_live_tpevent)
    } catch (error) { }
  }, [events, comment])

  useEffect(
    () => {
      if (matchInfo && matchInfo.homePlayers && matchInfo.awayPlayers) {
        const player = [...matchInfo.homePlayers, ...matchInfo.awayPlayers].find(p => p.id === comment.fk_jogador)
        let text = comment.text
          .replace('{img}', `<img width="60" style="margin-right: .5rem;" src="${(player && player.icon) ? player.icon : no_photo}"/>`)
          .replaceAll('{img}', '')
          .replaceAll('{style_ini}', '')
          .replaceAll('{style_fim}', '')
        if (comment.fk_live_tpevent === zerozeroVideosId) {
          let img = text.match(/<img src=".*" alt=".*" \/>/g)
          if (img) {
            img = img[0].slice(0, 5) + 'width="400" ' + img[0].slice(5)
          }
          const label = text.match(/<i>.*<\/i>/g)
          text = (img ? img : '') + label
        }
        else if (comment.fk_live_tpevent === photosId) {
          const text_array = text.split('|');
          text = `<img style="width:100%; max-width:400px; background:#23323f;" src="${text_array[0]}" alt="Uploaded image" />${text_array[1] !== '' ? `<p>${text_array[1]}</p>` : ''}`;
        }
        else if (comment.fk_live_tpevent === uniformId && !text.match('<svg')) {
          text = text.trim();
          const uniformNumber = parseInt(text.slice(-1));
          if (!isNaN(uniformNumber)) {
            const uniformText = text.substring(0, text.length - 1);
            const shirts = comment.fk_equipa === matchInfo.awayID ? matchInfo.awayShirts : matchInfo.homeShirts;
            text = uniformText + shirts[uniformNumber];
          }
        }

        setCommentHtml(text)
      }
    },
    [comment.fk_jogador, matchInfo, comment.text, comment.fk_live_tpevent, comment.fk_equipa],
  )

  useEffect(() => {
    setIsCataloged((comment?.catalogacao ? comment.catalogacao === "1" : true) && (comment?.catalogacaopen ? comment.catalogacaopen === "1" : true));
  }, [comment, comment.catalogacao, comment.catalogacaopen])

  const onClickComment = (e) => {
    if (canEdit && comment.id !== '') { setupEditComment(e) }
  }

  const toggleFullScreen = (e) => {
    if (!e.target.fullscreenElement) {
      if (e.target.requestFullscreen) e.target.requestFullscreen()
      else if (e.target.webkitRequestFullscreen) e.target.webkitRequestFullscreen()
      else if (e.target.msRequestFullscreen) e.target.msRequestFullscreen()
      else if (e.target.mozRequestFullScreen) e.target.mozRequestFullScreen()
      else setShowImageFullScreen(true)
    }
  }

  const wasDeleted = () => {
    const deletedComments = localStorage.getItem('deletedComments');
    const dComments = deletedComments ? JSON.parse(deletedComments) : [];
    const foundComment = dComments.find((element) => element === comment.id)
    return foundComment ? true : false;
  }

  const resendComment = (e) => {
    handleResend(id)
  }

  if (!wasDeleted())
    return (
      <div style={{ position: "relative" }} className={styles.hoverDiv}>
        <Row className={styles.row_comments} style={(comment.id === '' ? { backgroundColor: BACKGROUND_EVENT_OFFLINE } : null)} onClick={(e) => { if (comment.fk_live_tpevent !== photosId) onClickComment(e) }}>
          <div className={styles.col_user_comments}
            style={comment.id === '' ? { backgroundColor: EVENT_OFFLINE } : (comment.fk_user === userId ? { backgroundColor: EVENT_USER } : null)}></div>
          <Col className={styles.col_min_time}>
            <span className={classNames(styles.cmt_time)}>
              {comment.ignore_minute === '1' ? null : `${comment.minute}'`}
            </span>
            <span className={classNames(styles.cmt_time_extra)}>
              {comment.ignore_minute === '1'
                ? null
                : comment.minute_extra === '0'
                  ? null
                  : `+${comment.minute_extra}`}
            </span>
            {comment.marcador && matchInfo.te_modalidade < MODALITIES_MARCADOR_INDEX && (<span className={classNames(styles.cmt_current_score)}>({comment.marcador})</span>)}
          </Col>
          <Col className={styles.col_cmt_icon}>
            <div className={styles.cmt_icon_container}>
              <img
                alt=""
                src={icon}
                className={classNames('rounded-circle', styles.event_icon)}
              />
            </div>
            {isZZMember && comment.id === '' && (
              <Button onClick={resendComment}>{f({ id: "resend" })}</Button>
            )}
          </Col>
          <Col className={styles.col_cmt_text}>
            <div
              className={classNames(
                'd-flex',
                'justify-content-start',
                styles.mb_adaptation,
              )}
            >
              {comment?.img_jogador_path && (
                <img alt='comment-img' width={comment.img_jogador_width} height={comment.img_jogador_height} src={'http://' + comment.img_jogador_path} style={{ borderRadius: "50%", margin: "0rem 1rem 1rem 0.5rem" }} />
              )}
              <div
                data-testid="comment"
                className={styles.comment_text_box}
                style={(comment.id === '' ? { color: TEXT_EVENT_OFFLINE } : null)}
                dangerouslySetInnerHTML={{ __html: commentHtml }}
                onClick={(e) => {
                  if (comment.fk_live_tpevent === photosId) {
                    toggleFullScreen(e)
                  }
                }}
              />
            </div>
          </Col>
          {resolveConflict
            ? <Col className={styles.col_options_icon}>
              <div data-testid="delete" className={styles.opt_icon_container} onClick={setupDeleteComment}>
                <img
                  data-testid="deleteButton"
                  alt='delete button'
                  title="delete"
                  src={deleteIcon}
                  className={classNames(styles.delete, styles.header_icon, styles.cursor_on)}
                />
              </div>
            </Col>
            : <Col className={styles.noWidth}></Col>}
          {canEdit && !resolveConflict
            ? <Col className={styles.col_options_icon} onClick={(e) => { if (comment.fk_live_tpevent === photosId) onClickComment(e) }}>
              <div data-testid="options" className={styles.opt_icon_container} onClick={onClickComment}>
                <img
                  src={overflowIcon}
                  alt="Overflow"
                  data-testid="optionsButton"
                  className={classNames(
                    styles.cursor_on,
                    styles.icon_container)}
                ></img>
              </div>
            </Col>
            : <Col className={styles.noWidth}></Col>}
          {needsCatalog || needsPenCatalog ? (isCataloged
            ? <FontAwesomeIcon className="m-2" icon={faCheck} size="lg" color='green' />
            : canEdit ? <Button style={{ borderRadius: '0' }}>
              <FontAwesomeIcon className="m-2" icon={faMarker} size="sm" />
            </Button>
              : <></>
          )
            : <></>}
        </Row>
        {arrowUpActive && (
          <Button className={`${styles.arrowBtns} ${styles.upArrowBtn}`} onClick={() => onChangeOrder(comment.id, 'up')}>
            <FontAwesomeIcon icon={faAngleUp} size="sm" />
          </Button>)}
        {arrowDownActive && (
          <Button className={`${styles.arrowBtns} ${styles.downArrowBtn}`} onClick={() => onChangeOrder(comment.id, 'down')}>
            <FontAwesomeIcon icon={faAngleDown} size="sm" />
          </Button>)}
        <Modal
          show={showImageFullScreen}
          onHide={() => setShowImageFullScreen(false)}
          centered
          className={styles.imageModal}
        >
          <div
            dangerouslySetInnerHTML={{ __html: commentHtml.replace(/max-width:.*?;/, 'border-radius: .25em;') }}
            onClick={() => setShowImageFullScreen(false)}
          />
        </Modal>
      </div>
    )

  return <></>
}

export default Comment
