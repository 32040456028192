import React, { useEffect, useRef } from 'react'
import { Card, Row } from 'react-bootstrap'
import PropTypes from 'prop-types'
import Comment from './Comment'
import styles from './Comment.module.scss'
import { useIntl } from 'react-intl'
import classNames from 'classnames'

const Comments = ({
  matchInfo,
  matchBegin,
  matchFinished,
  comments,
  events,
  setupEdit,
  scrollingDown,
  eventsOpen,
  onChangeOrder,
  handleResend,
}) => {
  const messagesEndRef = useRef(null)
  const { formatMessage: f } = useIntl()
  const scrollToBottom = () => {
    if (scrollingDown) {
      try {
        messagesEndRef.current.scrollIntoView({ behavior: 'smooth' })
      } catch (error) {}
    }
  }

  useEffect(scrollToBottom, [comments])

  const displayComments = comments.map((comment, i) => (
    <Comment
      key={i}
      matchInfo={matchInfo}
      comment={comment}
      events={events}
      id={i}
      setupEdit={setupEdit}
      canEdit={true}
      arrowUpActive={i !== 0 && comments[i - 1].minute === comment.minute && comments[i - 1].minute_extra === comment.minute_extra}
      arrowDownActive={i !== comments.length - 1 && comments[i + 1].minute === comment.minute && comments[i + 1].minute_extra === comment.minute_extra}
      onChangeOrder={onChangeOrder}
      handleResend={handleResend}
    />
  ))

  return (
    <Card.Body
      className={classNames(
        styles.msg_card_body,
        eventsOpen ? styles.eventsOpen : '',
      )}
    >
      {displayComments}
      <div ref={messagesEndRef} />
      <Row className={styles.row_matchFinished}>
        {matchFinished === '1' && (
          <span className={styles.span_matchFinished}>
            {f({ id: 'match.gameFinished' })}{' '}
          </span>
        )}
      </Row>
    </Card.Body>
  )
}

Comments.propTypes = {
  matchBegin: PropTypes.string.isRequired,
  matchFinished: PropTypes.string.isRequired,
  comments: PropTypes.array.isRequired,
  events: PropTypes.array.isRequired,
  setupEdit: PropTypes.func.isRequired,
  scrollingDown: PropTypes.bool.isRequired,
  eventsOpen: PropTypes.bool,
  catalogOptions: PropTypes.object,
  onChangeOrder: PropTypes.func.isRequired,
  handleResend: PropTypes.func.isRequired
}
export default Comments
